import jQuery from "jquery";

jQuery(document).ready(function($) {

    var dataGlobal = null;

    $('.competency-framework-form').submit(function(e) {
        e.preventDefault();

        var frameworkId = $('#frameworkSelect').val();

        if(frameworkId == 0) {
            return;
        }

        $.ajax({
            url: themosis.ajaxurl,
            type: 'POST',
            dataType: 'json',
            data: {
                action: 'competencyGetFrameworks',
                form_data: frameworkId
            }
        }).done(function(data) {
            $('.competency-framework-family-form').css('opacity',1);
            $('.competency-framework-family-form .single-field').html(data);
        });
    });

    $('.competency-framework-family-form').submit(function(e) {
        e.preventDefault();

        var frameworkFamilyId = $('#frameworkFamilySelect').val();

        $.ajax({
            url: themosis.ajaxurl,
            type: 'POST',
            dataType: 'json',
            data: {
                action: 'competencyGetFamilies',
                form_data: frameworkFamilyId
            }
        }).done(function(data) {
            $('.competency-data-table-outer').html(data);
            //alert(data);
        });
    });
});