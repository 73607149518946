import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import competencyChecker from "./competency-checker";
import Splide from "@splidejs/splide";
import featherLight from "featherlight";
import scrollTo from "jquery.scrollto";

$(document).ready(function() {

  // Announcement bar
  if ($('.announcement-bar')[0]) {
    // Close announcement bar
    $('.close-bar').click(function(){
        $('.announcement-bar').remove();
        $('body').removeClass('announcement-enabled');
    });
  }

    
  var windowWidth = $(window).width();

  $(".tg-hamburger").data("status", "false");

  $(".tg-hamburger").on("click", function() {
    if ($(this).data("status") === "false") {
      $("body").addClass("nav-open");
      $("#brand").addClass("brand-inverted");
      $(".tg-hamburger-outer").addClass("open");
      $(".header__nav__container").fadeIn();
      $(this).data("status", "true");
    } else if ($(this).data("status") === "true") {
      $("body").removeClass("nav-open");
      $("#brand").removeClass("brand-inverted");
      $(".tg-hamburger-outer").removeClass("open");
      $(".header__nav__container").fadeOut();
      $(this).data("status", "false");
    }
  });

  $(".footer-menu .menu-outer").each(function() {
    $(this)
      .find("a")
      .first()
      .wrap('<div class="title"></div>');
  });

  if (windowWidth <= 768) {
    $(".header__nav__container a.has-sub-menu").on("click", function(e) {
      e.preventDefault();
      $(this)
        .parent()
        .find(".sub-menu")
        .fadeIn();
    });

    $(".footer-menu li .title").on("click", function(e) {
      e.preventDefault();
      $(this).toggleClass("open");
      $(this)
        .parent()
        .find(".sub-menu")
        .fadeToggle();
    });
  }

  var accoridion_content_tab = $(".accoridion-tab");
  var accoridion_images = $(".accoridion-image");

  $(".accoridion-tab").click(function() {
    var tab_id = $(this).attr("data-tab-num");
    var target_image = $('.accoridion-image[data-tab-num="' + tab_id + '"]');

    accoridion_content_tab.removeClass("active");
    $(this).addClass("active");

    accoridion_images.removeClass("active");
    $(target_image).addClass("active");
  });

  // SPLIDE SLIDER
  $(".splide").each(function() {
    new Splide(".splide").mount();
  });

  $(".splide-featured-cards").each(function() {
      new Splide(".splide-featured-cards").mount();
  });

  $(".splide-speakers").each(function() {
    new Splide(".splide-speakers").mount();
});

  $(window).on("hashchange", function(e) {
    var hash = window.location.hash;

    if (
      hash == "#andyandrews" ||
      hash == "#benhedges" ||
      hash == "#leahprevost"
    ) {
      $.featherlight($(hash));
    }
  });

  if (window.location.hash) {
    var hash = window.location.hash;

    if (
      hash == "#andyandrews" ||
      hash == "#benhedges" ||
      hash == "#leahprevost"
    ) {
      $(document).scrollTo($(".consultants"), 1500, {
        offset: -200,
        onAfter: function() {
          requestAnimationFrame(function() {
            $.featherlight($(hash));
          });
        },
      });
    }
  }
});

$(window).on("load", function() {
  $(".four_blocks .plus-button").on("click", function() {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this)
        .prev(".read-more-text")
        .slideUp();
    } else {
      $(this).addClass("active");
      $(this)
        .prev(".read-more-text")
        .slideDown();
    }
  });
  $(".four_blocks_with_picture .plus-button").on("click", function() {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this)
        .prev(".read-more-text")
        .slideUp();
    } else {
      $(this).addClass("active");
      $(this)
        .prev(".read-more-text")
        .slideDown();
    }
  });
});



// FAQs.
var faq_cards = document.querySelectorAll( '.faq-card' );
faq_cards.forEach(
	function (faq) {
		const question = faq.querySelector( '.faq-question' );
		question.addEventListener(
			'click',
			function () {
				let answer_wrap   = faq.querySelector( '.faq-answer-wrap' );
				let answer        = faq.querySelector( '.faq-body' );
				let answer_height = answer_wrap.offsetHeight;
				if (faq.classList.contains( 'open' )) {
					answer.style.height = '0px';
					faq.classList.remove( 'open' );
					answer_wrap.classList.remove( 'opacity-100' );
				} else {
					answer.style.height = answer_height + 'px';
					faq.classList.add( 'open' );
					answer_wrap.classList.add( 'opacity-100' );
				}
			}
		);
	}
);
